<template>
	<div class="bg-gray-50">
		<Html>
			<Head>
				<Title>Subscribe or Buy Your Next Car | carbar Australia</Title>
				<Meta
					name="description"
					content="Buy, finance or subscribe to your next car online. Sell your car without haggling, get an instant valuation."
				/>
			</Head>
		</Html>

		<DefaultSection>
			<div class="relative flex flex-col items-center space-y-5">
				<div class="z-20 w-[380px] bg-white shadow-lg">
					<div class="p-5">
						<h3
							class="m-0 w-full bg-white text-left font-body text-xl font-medium"
						>
							Login
						</h3>
						<h4 class="mb-4"></h4>
						<form method="post" @submit="onSubmit">
							<div class="flex flex-col space-y-2">
								<InputField
									v-model="email"
									name="email"
									label="Email"
									:disabled="isSubmitting || loginProcessing"
									:error="
										errors.email
											? errors.email
											: errors._recaptcha
												? errors._recaptcha
												: setFieldErrors
									"
									:show-error="setFieldErrors ? false : true"
									@input="clearServerErrors"
								/>
								<InputField
									v-model="password"
									name="password"
									label="Password"
									:disabled="isSubmitting || loginProcessing"
									:error="errors.password || setFieldErrors"
									:type="'password'"
									:show-error="setFieldErrors ? false : true"
									@input="clearServerErrors"
								/>
							</div>
							<ClientOnly>
								<TransitionGroup name="alert">
									<AlertBox
										v-if="
											isCredentialsInvalid &&
											!serverErrors
										"
									>
										Your email or password is incorrect.
										Please try again or
										<CarbarLink
											href="/reset-password"
											font-size="text-base-xsm"
											text-color="text-gray-575"
											text="reset your password"
										/>
										<span>.</span>
									</AlertBox>
									<AlertBox v-if="serverErrors">
										<AlertMessages
											:messages="serverErrors"
											:show-icon="false"
										/>
									</AlertBox>
									<AlertBox
										v-if="
											userStatus === 1 || userStatus === 3
										"
									>
										If this email belongs to you, please
										verify the email in order to complete
										the account creation.
										<CarbarLink
											href="#"
											font-size="text-base-xsm"
											text-color="text-gray-575"
											:onclick="resendValidationEmail"
											text="Resend verification email"
										/>
										<div
											v-if="showResendLoader"
											class="absolute left-1/2 top-1/2 flex h-6 w-6 -translate-x-1/2 -translate-y-1/2 justify-center align-middle"
										>
											<IconsIconLoading />
										</div>
									</AlertBox>
									<AlertBox v-if="resendServerErrors">
										<AlertMessages
											:messages="resendServerErrors"
											:show-icon="false"
										/>
									</AlertBox>
									<AlertBox
										v-if="serverSuccessMessages"
										:type="'green'"
									>
										{{ serverSuccessMessages }}
									</AlertBox>
									<AlertBox
										v-if="successMessages"
										:type="'green'"
									>
										{{ successMessages }}
									</AlertBox>
								</TransitionGroup>
							</ClientOnly>
							<div class="mt-3">
								<CarbarButton
									class="w-full"
									type="submit"
									:background-color="'bg-blue-400'"
									:disabled="
										isSubmitting ||
										loginProcessing ||
										!meta.dirty ||
										!meta.valid
									"
									:show-loader="
										isSubmitting || loginProcessing
									"
								>
									Login
								</CarbarButton>
							</div>
						</form>
						<div class="mt-4 text-xsm text-gray-575">
							This site is protected by reCAPTCHA and the Google
							<CarbarLink
								:href="'https://policies.google.com/privacy'"
								:font-size="`text-xsm`"
								text="privacy policy"
							/>

							and
							<CarbarLink
								:href="'https://policies.google.com/terms'"
								:font-size="`text-xsm`"
								text="terms of service"
							/>
							apply.
						</div>
						<div class="mt-5">
							<CarbarLink
								:href="'/forgot-password'"
								:underline="false"
								class="w-full"
							>
								<CarbarButton :is-outline="true">
									Forgot my password
								</CarbarButton>
							</CarbarLink>
						</div>
					</div>
				</div>
				<div
					class="relative z-20 bg-white"
					style="width: 380px; height: 157px"
				>
					<div class="space-y-2.5 p-5">
						<h4>I am new.</h4>
						<div class="text-gray-575">
							Join now. It's free to become a member
						</div>
						<div>
							<CarbarLink
								:href="'/sign-up#individual'"
								:underline="false"
								class="w-full"
							>
								<CarbarButton :min-width="`min-w-full`">
									Create new account
								</CarbarButton>
							</CarbarLink>
						</div>
					</div>
				</div>
				<div
					class="absolute -right-2 bottom-0 font-heading font-bold text-gray-600 opacity-5 lg:-bottom-20"
					style="font-size: 200px"
				>
					Login
				</div>
			</div>
		</DefaultSection>
	</div>
</template>

<script setup>
import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'
import CarbarLink from '@/components/links/CarbarLink.vue'
import InputField from '@/components/inputs/InputField.vue'
import DefaultSection from '@/components/sections/DefaultSection.vue'
import CarbarButton from '@/components/buttons/CarbarButton.vue'
import { useCarbarAuAuthStore } from '@/stores/carbar-au-auth'
import AlertBox from '@/components/alerts/AlertBox.vue'
import AlertMessages from '@/components/alerts/AlertMessages.vue'

const route = useRoute()

const userStatus = ref(0)
const userState = ref(0)
const showResendLoader = ref(false)
const resendServerErrors = ref()

const { handleSubmit, isSubmitting, errors, meta } = useForm({
	validationSchema: yup.object({
		email: yup
			.string()
			.trim()
			.email('Enter valid email')
			.required('Email is required')
			.label('Email'),
		password: yup
			.string()
			.required('Password is required')
			.label('Password'),
	}),
})

const { value: email } = useField('email')
const { value: password } = useField('password')

const setFieldErrors = computed(() => isCredentialsInvalid.value)
const isCredentialsInvalid = ref(false)
const serverErrors = ref()
const serverSuccessMessages = ref()
const successMessages = computed(() =>
	route.query.success_message ? route.query.success_message : null
)

const loginProcessing = ref(false)

const isLoggedInThenRedirect = async () => {
	if (isLoggedIn()) {
		try {
			const { data } = await useFetchForCarbarAu(
				'/api/account/verify-logged-in'
			)

			if (data?.success) {
				return navigateToWithOverride('/account', { external: true })
			}
		} catch (e) {
			// do nothing
		}

		signOut()
	}
	return null
}

onServerPrefetch(() => {
	isLoggedInThenRedirect()
})

onBeforeMount(() => {
	isLoggedInThenRedirect()
})

const onSubmit = handleSubmit(async (values) => {
	loginProcessing.value = true

	// clear any server error on re-submit
	isCredentialsInvalid.value = false
	userStatus.value = null
	userState.value = null
	serverErrors.value = null

	const authStore = useCarbarAuAuthStore()

	await useExecuteRecaptcha('login').then(async (token) => {
		try {
			await authStore.signIn(values.email, values.password, token)
		} catch (error) {
			if (error.errors) {
				if (error.errors?.credentials === 'invalid') {
					isCredentialsInvalid.value = true
				} else {
					serverErrors.value = error.errors
					isCredentialsInvalid.value = true
				}
			}
			userStatus.value = error.status ?? null
			userState.value = error.state ?? null

			loginProcessing.value = false
		}
	})
})

const resendValidationEmail = async (event) => {
	event.preventDefault()
	if (showResendLoader.value) {
		return
	}
	showResendLoader.value = true
	resendServerErrors.value = null
	serverSuccessMessages.value = null

	try {
		const authStore = useCarbarAuAuthStore()
		serverSuccessMessages.value = await authStore.resendVerificationEmail(
			email.value
		)

		userStatus.value = 0
		showResendLoader.value = false
	} catch (error) {
		if (error?.errors?.email) {
			resendServerErrors.value = error.errors.email
			userStatus.value = null
		}
	}
}

const clearServerErrors = () => {
	isCredentialsInvalid.value = false
	serverErrors.value = null
}
</script>
